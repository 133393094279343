import React from "react";
import "./index.css";
import ReactPlayer from "react-player";
import { Container, Typography } from "@mui/material";
import styled from "styled-components";
import sitex from "../../images/assets/SITEX69.mp4"

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
    padding-bottom: 20px;
  }
`;

const About = () => {
  return (
    <div className="video_container" id="documentation">
      <Title>Making of PixlBytes</Title>
      <Desc>Behind the scenes of our work</Desc>
      <Container maxWidth="md" style={{ display: "flex", justifyContent: "center" }}>
        <div className="player__wrapper">
          <ReactPlayer
            className="player"
            url={sitex}
            width="100%"
            height="100%"
            playing={false}
            muted={true}
            controls={true}
          />
        </div>
      </Container>
    </div>
  );
};

export default About;
