import styled from "styled-components";

export const Div = styled.div`
  width: 600px;
  height: 200px;
  margin-right: 250px;
  @media only screen and (max-width: 768px) {
    margin-right: -10px;
    margin-top: -250px;
  }
`;
