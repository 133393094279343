import './App.css';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './utils/Themes';
import Navbar from './components/Navbar';
import Hero from './components/HeroSection';
import Skills from './components/Skills';
import Education from './components/Education';
import { BrowserRouter as Router } from 'react-router-dom';
import Team from './components/Team'
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact/index';
import Footer from './components/Footer';
import SEO from './components/SEO'
import Expirence from './components/Expirence';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ProjectDetails from './components/Projects/ProjectDetails';

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  return (
    <>
  <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <Router>
    <SEO
				title="PixlBytes - Official"
				description="Reach Us To make your website"
			/>
    <Navbar/>
      <Body>
        <Hero/>
        <Wrapper>
        <Skills/>
        <About/>
        <Expirence/>
        </Wrapper>
        <Projects openModal={openModal} setOpenModal={setOpenModal}/>
        <Wrapper>
        <Education/>
        <Team openModal={openModal} setOpenModal={setOpenModal}/>
        <Contact/>
        </Wrapper>
        <Footer/>
        {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
      </Body>
    </Router>
    </ThemeProvider> 
  </>
    );
}

export default App;
