import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './TeamStyle'
import TeamCard from '../Cards/TeamCard'
import { team } from "../../data/constant";

const Team = () => {
  return (
    <Container id="team">
      <Wrapper>
        <Title>Our Teams</Title>
        <Desc>
            Get to know the people behind the scenes who turn your vision into reality.
        </Desc>
        <CardContainer>
            {team.map((team, index) => (
              <p key={index}>
                <p sx={{ py: "12px", px: 2 }}>
                  <TeamCard team={team} />
                </p>
              </p>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Team